<template>
  <div>
    <a-tabs
      type="card"
      class="sm-mt-20"
      :active-key="currentComponent"
      @change="changeTab"
    >
      <a-tab-pane
        v-for="item in tabs"
        :key="item.component"
        :tab="item.name"
      />
    </a-tabs>

    <component
      :is="currentComponent"
    />
  </div>
</template>

<script>
export default {
  name: 'DocumentShowTab',
  components: {
    Info: () => import('@/pages/document/list/details/Info'),
    Preview: () => import('@/pages/document/list/details/preview/index'),
    Comments: () => import('@/pages/document/list/details/Comments')
  },
  data() {
    return {
      currentComponent: 'Info',
      tabs: [
        { component: 'Info', name: '基本信息' },
        { component: 'Preview', name: '文档预览' },
        { component: 'Comments', name: '用户评论' }
      ]
    }
  },
  methods: {
    changeTab(key) {
      this.currentComponent = key
    }
  }
}
</script>
